import React from 'react'

import SEO from '../components/seo'

import { BasicPageContain, CopyBlockStyle, Grid, GridLeft, GridRight } from '../utils/copperTypography';


const SourcesPage = () => (
  <BasicPageContain>
    <SEO title="Terms and Conditions" />
    <CopyBlockStyle>
      <h3>Copper Compression</h3>
      <h1>Sources and References</h1>
      <Grid>



        <GridLeft>
          <h3>Information</h3>
          <p>Please find links and references to related studies and other information sources used on site.</p>
        </GridLeft>

        <GridRight>
          <h3>Sources, References, &amp; Additional Links</h3>

          <p className="wide">Duffield, R., Cannon, J., King, M. (2010). The effects of compression garments on recovery of muscle performance following high-intensity sprint and plyometric exercise. Journal of Science and Medicine in Sport 13 (2010) 136–140</p>

          <a href="http://www.nrcresearchpress.com/doi/abs/10.1139/apnm-2015-0335#.Vun5zykpfHw">nrcresearchpress.com</a>

          <br /><br />
          
          <p className="wide">Xavier, Valle (2014) Compression garments to prevent delayed onset muscle soreness in soccer players. Muscles Ligaments Tendons J. 2013 Oct-Dec; 3(4): 295–302. Published online 2014 Feb 24.</p>

          <a href="http://www.ncbi.nlm.nih.gov/pmc/articles/PMC3940503/">
          ncbi.nlm.nih.gov</a>

          <br /><br />

          <p className="wide">Gregor Grass, Christopher Rensing, and Marc Solioz, Metallic Copper as an Antimicrobial Surface, Applied Environmental Microbiology, 2011 March; 77(5): 1541-1547 and</p>

          <a href="http://www.ncbi.nlm.nih.gov/pmc/articles/PMC3067274/">ncbi.nlm.nih.gov</a>
          
          <br /><br />

          <p className="wide">Kraemer, W. J., J. A. BUSH,R.U.NEWTON, et al. Influence of compression garment on repetitive power output production before and after different types of muscle fatigue.Sports Med. Training Rehabil.8:163–184, 1998</p>
          
          <a href="http://libres.uncg.edu/ir/asu/f/triplett_travis_1998_compression_garments.pdf">libres.uncg.edu</a>

          <br /><br />

          <p className="wide">Born D.P., Sperlich B., Holmberg H.C., Bringing Light into the Dark: Effects of Compression Clothing on Performance and Recovery, International Journal of Sports Physiology and Performance, 2013 Jan;8(1):4-18</p>
          
          <a href="http://www.ncbi.nlm.nih.gov/pubmed/23302134">ncbi.nlm.nih.gov</a>

          <br /><br />

          <p className="wide">Trenell M.I., Rooney K.B., Sue C.M,, Thompson C.H., Compression Garments and Recovery from Eccentric Exercise: A 31P-MRS Study, Journal of Sports Science and Medicine (2006) 5, 106-114, published March 2006</p>
          
          <a href="http://www.jssm.org/vol5/n1/12/v5n1-12pdf.pdf">jssm.org</a>

          <br /><br />

          <p className="wide">Bringard, A., Perrey, S., &amp; Belluye, N. (2006). Aerobic energy cost and sensation responses during submaximal running exercise –positive effects of wearing compression tights. Int J Sports Med 2006; 27: 373–378</p>
          
          <a href="https://www.researchgate.net/publication/7054068_Aerobic_Energy_Cost_and_Sensation_Responses_During_Submaximal_Running_Exercise_-_Positive_Effects_of_Wearing_Compression_Tights">researchgate.net</a>

          <br /><br />

          <p className="wide">Duffield R, Edge J, Merrells R, Hawke E, Barnes M, Simcock D, Gill N., The Effects of Compression Garments on Intermittent Exercise Performance and Recovery on Consecutive Days, International Journal of Medicine and Sports Physiology and Performance, 2008, 3, 454-468</p>
          
          <p className="wide">Driller M.W., Halson S.L., (2013), The effects of lower-body compression garments on recovery between exercise bouts in highly-trained cyclists, Journal of Science and Cycling, 1(2): 45-50</p>
          
          <p className="wide">Kraemer W.J., Volek J.S., Bush J.A., Gotshalk L.A., Wagner P.R., Mazzetti S.A., Selle B.J., Influence of compression hosiery on physiological responses to standing fatigue in women, Medicine &amp; Science in Sports &amp; Exercise (2000) 2, 0195-9131/00/3211-1849/0, 1849-1858</p>
          
          <a href="http://bertec.com/uploads/pdfs/papers/Medicine%20and%20Science%20in%20Sports%20and%20Exercise/Kraemer-Med%20Sci%20Spor%20Exerc-2000.pdf">bertec.com</a>

          <br /><br />

          <p className="wide">Jakeman, J.R., C. Byrne, R.G. Eston. 2010. "Lower limb compression garment improves recovery from exercise- induced muscle damage in young, active females." European Journal of Applied Physiology, 109(6):1137-44.</p>

        </GridRight>
      </Grid>
    </CopyBlockStyle>
  </BasicPageContain>
)

export default SourcesPage
